/* eslint-disable no-useless-escape */
export const regexPatterns = {
  searchPattern: /^[a-zA-Z0-9_ .ÁÉÍÓÚÑáéíóúñçÇÀÈÌÒÙàèìòùÂÊÎÔÛâêîôûÄËÏÖÜäëïöü()\-(/)£%".,@‘:;\\!&+?$=*~#<>']{1,150}$/,
  emailPattern: /^(?!.{101})([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/,
  settings: {
    timesheets: {
      changeStartDay: {
        notePattern: /^[A-Za-z0-9_ ',!".£@$%^&*()\[\]\n\r]{0,500}$/,
      },
    },
  },
  messages: {
    titlePattern: /^[a-zA-Z0-9_ .\?';:!"`@–#+*%£$,’ÁÉÍÓÚÑáéíóúñçÇÀÈÌÒÙàèìòùÂÊÎÔÛâêîôûÄËÏÖÜäëïöü()\/&-]{1,600}$/,
    descriptionPattern: /^(.{1,2000})$/m,
  },
  groups: {
    user: {
      namePattern: /^([A-Za-z0-9])\w+$/,
    },
    document: {
      namePattern: /^[a-zA-Z0-9]{1,50}$/,
    },
  },
  timesheets: {
    notesPattern: /^(?!\s)[a-zA-Z0-9_ ',!\".£@$%^&?*;()\[\]\n\r]{0,500}$/,
    rejectPattern: /^(?!\s)[a-zA-Z0-9_ ',!\".£@$%^&?*;()\[\]\n\r]{0,500}$/,
  },
  payTypes: {
    name: /^[A-Za-z0-9_ !"\.£@$%^&\*\(\)\[\]]{1,30}$/,
  },
  startersLeavers: {
    name: /^[a-zA-Z0-9_.ÁÉÍÓÚÑáéíóúñçÇÀÈÌÒÙàèìòùÂÊÎÔÛâêîôûÄËÏÖÜäëïöü\s()£%\",@‘\-:;/!&+?$=*~#<>']{1,60}$/,
    rejectReason: /^(?!\s)[a-zA-Z0-9_ ',;!".£@$%^&*()[\]\n\r]{0,500}$/,
  },
  documents: {
    rejectReasonPattern: /^(?!\s)[a-zA-Z0-9_ ',!"\.£@$%^&\*\(\)\[\]\n\r]{1,500}$/,
  },
  payslips: {
    partialNiNumber: /^(\w{2})?\d{1,6}\w?$/,
  },
};

export const routeRegex = (scope, route) => {
  return new RegExp(
    // eslint-disable-next-line no-useless-escape
    `\/(${scope})\/([0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12})\/(${route})`,
    'gi'
  );
};
